
.chart-contract-types {
    display: flex;
    align-items: center;
    padding: 6px 4px 16px 12px;
    margin: auto 0;
    overflow: hidden;
    .chart-legend {
        padding-left: 12px;
    }
}

@media screen and (max-width: 479px) {
    .chart-contract-types {
        flex-direction: column;
        padding: 0;
    }

    .chart-legend {
        padding-left: 0;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
