
.chart-tooltip {
    opacity: 0;
    top: 50%;
    position: absolute;
    z-index: 99999;
    font-size: 13px;
    color: #fff;
    background: #2c2c2c;
    border-radius: 6px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.65);
    border: 1px solid var(--card-border-color);    
    pointer-events: none;
    transition: .12s all ease;
    &__items {
        display: flex;
        flex-direction: row;
        list-style: none;
        appearance: none;
        margin: 0;
        padding: 0;
    }
    &__column {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 96px;
    }
    &__header {
        padding: 6px 12px;
        font-weight: 500;
    }
    &__line {
        height: 2px;
        width: 100%;
    }
    &__footer {
        padding: 6px 12px;
    }
}
